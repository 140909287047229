/* message.css */

/* Container styling */
.message-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Header styling */
.message-header {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #e2e2e2;
}

.recipient-image {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 15px;
  object-fit: cover;
}

.recipient-info h5 {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  color: #333333;
}

.recipient-info p {
  margin: 0;
  font-size: 14px;
  color: #666666;
}

.dropdown-toggle {
  margin-left: auto;
  background: transparent;
  border: none;
  font-size: 24px; /* Size the icon appropriately */
  color: #333333; /* Color for visibility */
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* Icon styling for dropdown menu */
.dropdown-toggle::before {
  content: '\2630'; /* Unicode character for a hamburger menu */
  font-size: 24px; /* Adjust size to fit the design */
}

/* Dropdown menu styling */
.dropdown-menu {
  min-width: 160px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.dropdown-item {
  padding: 12px 16px;
  cursor: pointer;
  font-size: 14px;
}

.dropdown-item:hover {
  background-color: #f4f4f4;
}

/* Message body styling */
.message-body {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 5px;
  min-height: 70%;
  min-width: 70%;
}


/* Ensure the message items have a bottom margin */
.message-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  padding: 8px;
}

/* Date divider styling */
.date-divider {
  text-align: center;
  margin: 12px 0;
  color: #999999;
  font-size: 12px;
}

.message-item.sent {
  align-items: flex-end;
  text-align: right;
}

.message-item.received {
  align-items: flex-start;
  text-align: left;
}

.message-content {
  max-width: 70%;
  padding: 12px 16px;
  border-radius: 18px;
  background-color: #007bff;
  color: #ffffff;
  word-wrap: break-word;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.message-item.sent .message-content {
  background-color: #28a745;
}

.message-timestamp {
  font-size: 10px;
  color: #999999;
  align-self: flex-end;
}

/* Message input styling */
.message-input {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 15px 0; /* Adjust padding to ensure it sticks to the bottom */
  border-top: 1px solid #e2e2e2;
  background-color: #f9f9f9; /* Optional background color for the input area */
}

.message-input textarea {
  flex: 1;
  border-radius: 18px;
  padding: 12px 16px;
  border: 1px solid #d1d1d1;
  font-size: 14px;
  min-height: 40px; /* Ensure a minimum height */
}

.message-input button {
  border-radius: 18px;
  padding: 8px 16px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.message-input button:hover {
  background-color: #0056b3;
}

/* Modal styling */
.modal-content {
  border-radius: 8px;
}

.modal-header,
.modal-footer {
  border: none;
}

.modal-title {
  font-weight: 600;
  color: #333333;
}

.modal-body textarea {
  border-radius: 8px;
  resize: none;
  border: 1px solid #d1d1d1;
  padding: 12px;
  font-size: 14px;
}

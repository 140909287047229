/* Full-screen container with smooth animation */
.splash-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
    background: linear-gradient(135deg, #ff7e5f 0%, #feb47b 50%, #ff7e5f 100%);
    color: #fff;
    background-size: 300% 300%;
    animation: gradient 15s ease infinite;
    padding: 20px;
    box-sizing: border-box;
    overflow: hidden;
  }
  
  /* Gradient animation for background */
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  /* Compact contact email at the top-left with smooth fade-in */
  .contact-email {
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.8);
    animation: fadeIn 2s ease-in-out 1;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Adjusted top-right login/signup buttons with hover effects */
  .button-container {
    position: absolute;
    top: 15px;
    right: 20px;
    display: flex;
    gap: 12px;
    opacity: 0;
    animation: fadeIn 2s ease-in-out 1s forwards;
  }
  
  .splash-btn {
    background-color: rgba(255, 255, 255, 0.2);
    border: none;
    color: #fff;
    font-size: 0.9rem;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 12px;
    transition: transform 0.3s ease, background-color 0.3s ease;
  }
  
  .splash-btn:hover {
    background-color: rgba(255, 255, 255, 0.4);
    transform: scale(1.05);
  }
  
  /* Central content with reduced size */
  .splash-content {
    text-align: center;
    margin-top: 20px;
    opacity: 0;
    animation: fadeIn 2s ease-in-out 1.5s forwards;
  }
  
  .splash-logo {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
    border-radius: 50%;
    animation: bounce 1.5s infinite alternate;
  }
  
  /* Bouncing animation for logo */
  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-10px);
    }
  }
  
  .splash-title {
    font-size: 2.2rem;
    font-weight: bold;
    color: #fff;
    margin-bottom: 8px;
    text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
    animation: slideIn 1s ease-out 2s forwards;
  }
  
  /* Slide-in animation for title */
  @keyframes slideIn {
    0% {
      transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .splash-subtitle {
    font-size: 1.2rem;
    color: #fff;
    margin-bottom: 25px;
    animation: fadeIn 2s ease-in-out 2.5s forwards;
  }
  
  /* Grid layout for sections */
  .sections {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 15px;
    max-width: 1000px;
    margin-top: 30px;
    padding: 10px;
    opacity: 0;
    animation: fadeIn 2s ease-in-out 3s forwards;
  }
  
  /* Section cards with hover effects and rounded corners */
  .section-card {
    background: rgba(255, 255, 255, 0.15);
    border-radius: 15px;
    padding: 20px;
    text-align: center;
    color: #fff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    backdrop-filter: blur(8px);
    position: relative;
    overflow: hidden;
    animation: cardFadeIn 2s ease-in-out forwards;
  }
  
  @keyframes cardFadeIn {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .section-card:hover {
    transform: translateY(-10px) scale(1.05);
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.4);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  
  /* Footer at the bottom */
  .footer {
    text-align: center;
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.8);
    padding-top: 15px;
    margin-bottom: 10px;
    opacity: 0;
    animation: fadeIn 2s ease-in-out 4s forwards;
  }
  
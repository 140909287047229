/* Center the login container */
.login-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffdddd; /* Light red background */
  text-align: center; /* Center the text and logo */
}

/* Style the logo */
.logo {
  width: 100px; /* Adjust size as needed */
  height: auto;
  margin-bottom: 10px;
}

/* Style the header text */
.header-text {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
}

/* Style the subtitle text */
.subtitle-text {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

/* Style the login box */
.login-box {
  width: 400px;
  background-color: #fff;
  padding: 20px;
}

/* Style the form */
form {
  display: flex;
  flex-direction: column;
}

/* Style form inputs */
.form-control {
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Style form select inputs */
.form-select {
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Alert style */
.alert {
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
}

/* Button style */
.btn {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-primary:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Center the form link */
.form-link {
  text-align: center;
  margin-top: 10px;
}

/* Remove default link styles */
.btn-link {
  text-decoration: none;
  color: #007bff;
}

.btn-link:hover {
  text-decoration: underline;
}

/* General Navbar Styles */
.navbar-custom {
  background-color: #eff5f4; /* Dark gray background for modern look */
  border-bottom: 1px solid #dee2e6; /* Light border for subtle separation */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
}

.navbar-custom .navbar-brand {
  font-size: 1.75rem; /* Larger font size for branding */
  font-weight: 700; /* Bold font weight for branding */
  color: #007bff; /* Light color for text to contrast with dark background */
}

.navbar-custom .navbar-nav .nav-link {
  font-size: 1rem; /* Consistent font size for nav links */
  color:#007bff; /* Light color for nav links */
  padding: 0.75rem 1rem; /* Padding for better spacing */
  transition: color 0.3s, background-color 0.3s; /* Smooth transitions */
}

.navbar-custom .navbar-nav .nav-link:hover,
.navbar-custom .navbar-nav .nav-link.active {
  color: #007bff; /* Bright blue color for active and hover state */
  background-color: rgba(0, 123, 255, 0.2); /* Light background on hover */
  border-radius: 0.25rem; /* Rounded corners for hover effect */
}

.navbar-custom .nav-dropdown-menu {
  background-color: #343a40; /* Dark background for dropdown menu */
  border: 1px solid #495057; /* Slightly darker border for dropdown */
  border-radius: 0.25rem; /* Rounded corners for dropdown */
}

.navbar-custom .nav-dropdown-item {
  font-size: 0.875rem; /* Slightly smaller font size for dropdown items */
  color: #f8f9fa; /* Light color for dropdown items */
  padding: 0.5rem 1rem; /* Padding for dropdown items */
}

.navbar-custom .nav-dropdown-item:hover {
  background-color: #495057; /* Darker background on hover */
  color: #007bff; /* Bright blue color on hover */
}

.navbar-custom .navbar-toggler {
  border: 1px solid #495057; /* Darker border for the toggle button */
}

/* Mobile Styles */
@media (max-width: 992px) { /* For medium screens and below */
  .navbar-custom .navbar-nav {
    text-align: center; /* Center align the nav links */
  }

  .navbar-custom .navbar-nav .nav-link {
    padding: 0.5rem 0; /* Reduce padding for better mobile view */
    display: block; /* Make links block-level elements for stacking */
  }

  .navbar-custom .navbar-collapse {
    margin-top: 0.5rem; /* Add a bit of margin to collapse container */
  }
}

@media (max-width: 576px) { /* For small screens */
  .navbar-custom .navbar-brand {
    font-size: 1.5rem; /* Slightly smaller font size for brand name */
  }

  .navbar-custom .navbar-nav .nav-link {
    font-size: 0.875rem; /* Slightly smaller font size for links */
  }
}


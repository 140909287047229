/* Center the container and give it some padding */
.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Style the form labels */
  .form-label {
    font-weight: bold;
  }
  
  /* Add some space between the form groups */
  .form-group {
    margin-bottom: 15px;
  }
  
  /* Style the select input */
  .form-select {
    height: calc(2.25rem + 2px);
    border-radius: 4px;
  }
  
  /* Style the Pay Now button */
  .paystack-button {
    background-color: #28a745;
    border: none;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .paystack-button:hover {
    background-color: #218838;
  }
  
  /* Add some margin to the title */
  h2 {
    margin-bottom: 20px;
    color: #343a40;
  }
  
  /* Add some margin to the text center class */
  .text-center {
    margin-top: 20px;
  }
  
  /* Style the container and row */
  .container,
  .row {
    padding-top: 40px;
  }
  
  /* Style the card */
  .card {
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  }
  
  /* Style the form input */
  .form-control {
    border-radius: 0.25rem;
  }
  
/* home.css */

/* Container styles */
.home-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px; /* Add padding for breathing room */
  background-color: #fbf9f9; /* Light red background */
}

/* Filters section */
.filters {
  margin-bottom: 20px;
}

/* User list styles */
.user-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Adjust gap between user cards */
}
.message-icon {
  padding: 5px;
  right: 70px;
}

/* User card styles */
.user-card {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden; /* Ensure rounded corners */
  transition: transform 0.3s ease-in-out;
  width: 100%; /* Ensures full width on small screens */
  max-width: 300px; /* Adjust max-width as needed */
}

.user-card:hover {
  transform: translateY(-5px);
}

.user-card-img {
  height: 250px; /* Adjust height as needed */
  object-fit: cover;
  cursor: pointer;
}

.user-details {
  padding: 15px;
}

/* Like icon styles */
.like-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  cursor: pointer;
  color: #777;
  transition: color 0.3s ease-in-out;
}

.like-icon.filled {
  color: #dc3545; /* Red color when filled */
}

/* Modal user profile styles */
.modal-user-profile {
  padding: 20px;
  text-align: center;
}

.profile-image {
  width: 150px; /* Adjust width and height as needed */
  height: 150px;
  object-fit: cover;
  border-radius: 50%; /* Ensures rounded profile image */
}

.additional-images-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.additional-image {
  width: 200%; /* Adjust the width as per your design */
  max-width: 700px; /* Adjust the maximum width as needed */
  height: auto; /* Ensures images maintain their aspect ratio */
  margin: 5px; /* Adjust margin between images */
  border-radius: 8px; /* Rounded corners for images */
  cursor: pointer; /* Add pointer cursor on hover */
  transition: transform 0.3s ease-in-out; /* Smooth scaling on hover */
}

.additional-image:hover {
  transform: scale(1.05); /* Scale up slightly on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .user-card {
    max-width: 100%; /* Full width on smaller screens */
  }
}

@media (max-width: 576px) {
  .user-card-img {
    height: 200px; /* Adjust height for smaller screens */
  }

  .profile-image {
    width: 120px; /* Adjust profile image size */
    height: 120px;
  }
}
.filters {
  position: fixed;
  top: 90px;
  right: 20px;
  z-index: 10;
}

.filter-icon {
  font-size: 6rem;
  color: #007bff;
  cursor: pointer;
}

.footer {
  background-color: #343a40;
  color: #f8f9fa;
  text-align: center;
  padding: 15px;
  margin-top: auto;
}
.message-icon {
  padding: 5px;
  right: 20px;
}
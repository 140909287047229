/* Profile.css */

.container {
    margin-top: 20px;
  }
  
  .card-title {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .card-body {
    padding: 20px;
  }
  
  .list-group-item {
    font-size: 1rem;
    line-height: 1.5;
  }
  
  textarea.form-control {
    width: 100%;
    min-height: 100px;
  }
  
  .btn-primary {
    margin-top: 10px;
  }
  
  .profile-image-card {
    width: 100%;
    height: 400px; /* Adjust height as needed */
    object-fit: cover;
  }
  
  .additional-image-card {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .row-cols-md-3 {
    gap: 20px;
  }
  
  .card {
    margin-bottom: 20px;
  }
  
  .card-img-top {
    border-bottom: 1px solid #ddd;
  }
  
  .btn-danger {
    margin-top: 10px;
  }
  
.history-container {
    display: flex;
    flex-direction: column;
  }
  
  .history-link {
    text-decoration: none;
    color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .history-link:hover {
    text-decoration: none;
  }
  
  .font-weight-bold {
    font-weight: bold;
  }
  
  .text-primary {
    color: #007bff;
  }
  
  .text-secondary {
    color: #6c757d;
  }
  
  .mt-2 {
    margin-top: 0.5rem;
  }
  
  .mb-2 {
    margin-bottom: 0.5rem;
  }
  
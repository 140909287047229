.user-card-container {
  position: relative;
}

.user-card {
  transition: transform 0.3s ease-in-out;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.user-card:hover {
  transform: translateY(-5px);
}

.user-card-img {
  height: 350px; /* Adjust height as needed */
  object-fit: cover;
  cursor: pointer;
}

.user-details {
  padding: 15px;
}

.message-btn {
  margin-top: 10px;
}

.modal-user-profile {
  padding: 20px;
}

.profile-image {
  width: 150px; /* Adjust width and height as needed */
  height: 150px;
  object-fit: cover;
}

.additional-images {
  margin-top: 20px;
}

.additional-images-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.additional-image {
  width: 500px; /* Adjust width as needed */
  height: 500px; /* Adjust height as needed */
  object-fit: cover;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.timestamp {
  position: relative;
  bottom: 410px;

  right: -365px; /* Adjust as needed for positioning */
  background-color: rgba(0, 0, 255, 0.1); /* Blue background with opacity */
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8rem;
  color: #007bff; /* Blue text color */
}

@media (max-width: 768px) {
  .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.main-container {
  background-color: #ffdddd; /* Light red background */
  height: 100vh; /* Ensure it covers the entire viewport height */
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;





  

  
  
}

body {
  background-color: #e79999; /* Light red color */
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

/* Style for the signup container */
.signup-container {
  max-width: 600px;
  margin: 20px auto; /* Center the container and add top margin */
  padding: 20px;
  background-color: #ffffff; /* White background for the signup box */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Style for image previews */
.preview-image {
 

  max-width: 100%; /* Ensure the preview image does not overflow */
  height: auto; /* Maintain aspect ratio */
  border: 1px solid #ddd; /* Add a border for better visibility */
  border-radius: 4px; /* Rounded corners for images */
}

.logo {
  display: block;
  margin: 0 auto 20px;
  width: 50px;
}

.header-text {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.subtitle-text {
  text-align: center;
  font-size: 16px;
  color: #666;
}

.text-center {
  text-align: center;
}

.mb-4 {
  margin-bottom: 1rem;
}

.form-step {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
}

.form-group {
  margin-bottom: 1rem;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
}

.btn-secondary {
  background-color: #6c757d;
  color: #fff;
}

.image-preview {
  margin-top: 10px;
  max-width: 100%;
  height: auto;
}

.error-message {
  color: red;
  text-align: center;
  margin-top: 10px;
}
